<template>
  <div>
    <!-- Main Row -->
    <div class="row">
      <!-- Form -->
      <div class="col-md-12">
        <Card :title="'Detail Pasien'">
          <template v-slot:toolbar>
            <span class="text-danger"
              >Data Pasien yang ditampilkan disini adalah data yang berasal dari
              database Sistem Medhop {{ config.clinic_name }}</span
            >
          </template>
          <template v-slot:body>
            <div class="row align-items-center" v-if="dataLoaded == true">
              <!-- Profile Image -->
              <div class="col-md-4">
                <div
                  class="d-flex justify-content-center align-items-center image-container"
                >
                  <div style="height: 350px; width: 350px; position: relative">
                    <img
                      class="image"
                      v-if="data != null"
                      :src="data.patient_photo"
                    />
                  </div>
                </div>
              </div>

              <!-- Table Data -->
              <div class="col">
                <table class="table mb-3">
                  <tr>
                    <td><strong>ID Pasien</strong></td>
                    <td>{{ data.patient_id }}</td>
                  </tr>
                  <tr>
                    <td><strong>Nama</strong></td>
                    <td>{{ data.patient_name }}</td>
                  </tr>
                  <tr>
                    <td><strong>No. KTP</strong></td>
                    <td>{{ data.patient_id_card_number }}</td>
                  </tr>
                  <tr>
                    <td><strong>Tempat Tanggal Lahir</strong></td>
                    <td>
                      {{ data.patient_birt_place }} ,{{
                        data.patient_birt_date
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><strong>Umur</strong></td>
                    <td>{{ " Tahun" }}</td>
                  </tr>
                  <tr>
                    <td><strong>Jenis Kelamin</strong></td>
                    <td>{{ showPatientGender(data.patient_gender) }}</td>
                  </tr>
                  <tr>
                    <td><strong>Status</strong></td>
                    <td>{{ data.patient_status }}</td>
                  </tr>
                  <tr>
                    <td><strong>Alamat</strong></td>
                    <td>
                      {{
                        data.patient_address +
                        ", " +
                        data.patient_capital_name +
                        ", " +
                        data.patient_province_name
                      }}
                    </td>
                  </tr>
                </table>
                <button
                  @click="$router.push('/satu-sehat/dashboard')"
                  class="btn mx-1 btn-secondary"
                >
                  Kembali
                </button>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-12">
                <div class="row justify-content-between">
                  <div class="col-md-6 col-12">
                    <h5>Detail Rekam Medis</h5>
                  </div>
                  <div class="col-md-6 col-12">
                    <span class="text-danger"
                      >Data Rekam Medis Pasien yang ditampilkan disini adalah
                      data yang berasal dari Satu Sehat</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-12">
                <b-alert
                  variant="danger"
                  show
                  v-if="data.is_satusehat_upload == 0"
                  >Rekam medis belum diposting</b-alert
                >
                <b-alert
                  variant="success"
                  show
                  v-if="data.is_satusehat_upload == 1"
                  >Rekam medis sudah diposting</b-alert
                >
              </div>
              <div class="col-12 pt-0">
                <b-table
                  striped
                  hover
                  responsive="sm"
                  class="mt-3"
                  :items="encounter"
                  :fields="fieldMedicalRecord"
                >
                  <template #table-colgroup="scope">
                    <col v-for="field in scope.fields" :key="field.key" />
                  </template>

                  <template #cell(number)="data">
                    <span>{{ data.index + 1 }}</span>
                  </template>
                  <template #cell(period)="data">
                    <span>{{ formatDate(data.item.period.start) }}</span>
                  </template>
                  <template #cell(diagnosis)="data">
                    <ul>
                      <li v-for="(diagnose, i) in data.item.diagnosis" :key="i">
                        {{ diagnose.condition.display }}
                      </li>
                    </ul>
                  </template>
                  <template #cell(participant)="data">
                    <ul>
                      <li
                        v-for="(participant, i) in data.item.participant"
                        :key="i"
                      >
                        {{ participant.individual.display }}
                      </li>
                    </ul>
                  </template>
                  <template #cell(location)="data">
                    <span>{{ data.item.location[0].location.display }}</span>
                  </template>
                </b-table>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import moment from "moment";
import moduleBridge from "@/core/modules/CrudModuleBridge.js";
import { getConfiguration } from "@/core/services/jwt.service.js";

export default {
  components: {
    Card,
  },
  data() {
    return {
      config: getConfiguration(),
      data: {},
      dataSatuSehat: null,
      encounter: [],
      dataLoaded: false,
      fieldMedicalRecord: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "period",
          label: "Tanggal",
        },
        {
          key: "diagnosis",
          label: "Diagnosis",
        },
        {
          key: "participant",
          label: "Dokter",
        },
        {
          key: "location",
          label: "Lokasi",
        },
      ],
    };
  },
  methods: {
    showPatientGender(gender) {
      if (gender.toLowerCase() == "male") return "Laki-lai";
      else if (gender.toLowerCase() == "female") return "Perempuan";
      else if (gender.toLowerCase() == "perempuan") return "Perempuan";
      else if (gender.toLowerCase() == "laki-laki") return "Laki-lai";
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    async get() {
      this.data = await module.get("medical-records/" + this.$route.params.id);
      //   this.data["age"] = moment().diff(this.data.birt_date, "years");
      //   this.data["birt_date"] = moment(this.data.birt_date).format("DD-MM-YYYY");
      console.log('data rekmed', this.data)
      if (this.data == null) {
        Swal.fire({
          title: "Tidak ditemukan",
          text: "Data rekam medis gagal ditemukan",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        this.$router.push("/satu-sehat/dashboard");
      } else {
        this.dataLoaded = true;
        if (this.data.ss_encounter_id != null) {
          this.getMedicalRecordHistory(this.data.ss_encounter_id);
        }
        this.getPatientSatuSehat(this.data.ss_patient_id);

        console.log("data", this.data);
      }
    },
    async getPatientSatuSehat(ss_patient_id) {
      this.dataSatuSehat = await moduleBridge.get("patient/" + ss_patient_id);
      console.log("dataSatuSehat", this.dataSatuSehat);
    },
    async getMedicalRecordHistory(ss_encounter_id) {
      let response = await moduleBridge.get("encounter/" + ss_encounter_id);
      if (response != null) {
        let data = { ...response };
        this.encounter.push(data);
      }
        console.log("call encounter", this.encounter);
    },
  },
  mounted() {
    this.get();
  },
};
</script>

<style>
</style>